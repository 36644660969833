.customListFieldErrorBorder {
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border: 1px solid rgb(164, 38, 44);
    border-radius: 2px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    overflow: hidden;
}