#not-found {
  /* position: relative; */
  height: 100vh;
}

#not-found .not-found {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.not-found {
  max-width: 520px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

.not-found .not-found-404 {
  height: 190px;
}

.not-found .not-found-404 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 146px;
  font-weight: 700;
  margin: 0px;
  color: #232323;
}

.not-found .not-found-404 h1 > span {
  display: inline-block;
  width: 120px;
  height: 120px;
  background-image: url("./../../assets/emoji.png");
  background-size: cover;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  z-index: -1;
}

.not-found h2 {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  color: #232323;
}

.not-found p {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  color: #787878;
  font-weight: 300;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 767px) {
  .not-found .not-found-404 {
    height: 115px;
  }
  .not-found .not-found-404 h1 {
    font-size: 86px;
  }
  .not-found .not-found-404 h1 > span {
    width: 86px;
    height: 86px;
  }
}
