body {
    display: flex;
    font-family: "Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: .875rem;
    padding: 0;
    margin: 0;
    /* background-color: rgba(255,255,255,1);
    background-color: var(--background-color,rgba(255, 255, 255, 1));
    color: rgba(0,0,0,.9);
    color: var(--text-primary-color,rgba(0, 0, 0, .9)); */
    box-sizing: border-box;
}

/* body.lwp {
    font-size: .875rem;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
    height: 100%;
    width: 100%;
}

.CustomError {
    animation-name: css-40, css-53;
    animation-duration: 0.367s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
    animation-fill-mode: both;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin: 0px;
    padding-top: 5px;
    display: flex;
    align-items: center;
}

.CustomErrorLabel:after {
    content: " *";
    color: rgb(164, 38, 44);
    padding-right: 12px;
}

.PickerFieldErrorBorder .ms-BasePicker-text {
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border: 1px solid rgb(164, 38, 44);
    border-radius: 2px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    cursor: text;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    overflow: hidden;
}

.PickerFieldErrorBorder .ms-BasePicker-text::after {
    pointer-events: none;
    position: absolute;
    inset: -1;
    border: 2px solid rgb(164, 38, 44);
    border-radius: 2px;
}

.Tour {
    color: rgba(0,0,0,0.2);
}

/* grey bubble around numbers in grids */
.bolt-pill {
    background-color: var(--palette-black-alpha-6,rgba(0, 0, 0, .06));
    border-radius: 120px;
    border: 1px solid transparent;
    font-size: .6875rem;
    white-space: nowrap;
}

.bolt-pill-content {
    font-weight: 600;
    margin: 0 .5rem;
    padding: 2px 0;
}
/* grey bubble around numbers in grids */

.app-insights {
    display: flex;
    width: 100%;
}