.region-navigation {
    overflow: hidden;
    width: 50px;
    transition: width 1ms cubic-bezier(.4,0,.2,1);
}

.region-navigation.expanded {
    transition-duration: 200ms;
    width: 225px;
}

.region-navigation.collapsed {
    transition-duration: 200ms;
}

.expand-button {
    width: auto;
}

.region-navigation i {
    padding-left: 5px;
}

.app-Builder-Button {
    border-width: 0px;
    background: none;
}

.gear {
    align-self: center;
}

.region-navigation .ms-Nav-groupContent {
    margin-bottom: 0px;
}