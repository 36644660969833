.custom-command-bar {
    height: "46px";
}

.custom-command-bar-item .ms-Button-menuIcon {
    display: none;
}

#question-circle-svg {
    height: 20px;
    width: 20px;
}