.full-size {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .mobile-hidden {
        display: none;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
    .medium-small-screen-hidden {
        display: none;
    }
}